<template>
  <div class="">
    <div v-if="paginationType == 'basic'" class="flex items-center justify-between">
      <div class="flex items-center  basic-pagination">
        <div class="basic-pagination-label">Current Page:</div>
        <div class="pages">
          <span class="currentPage">{{page}}</span> / {{totalPages}}
        </div>
        <div class="btns">
          <button class="btn" :disabled="page === 1" @click="changePage(1)"><svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10a10 10 0 0120 0 10 10 0 01-20 0zm18.75 0a8.76 8.76 0 00-17.5 0 8.76 8.76 0 0017.5 0z" fill="#064782"/><path d="M10.13 10l4.37-4.38V3.75L8.25 10l6.25 6.25v-1.88L10.12 10z" fill="#064782"/><path d="M6.13 10l4.37-4.38V3.75L4.25 10l6.25 6.25v-1.88L6.12 10z" fill="#064782"/></svg></button>
          <button class="btn" :disabled="!hasPrev" @click="changePage(page - 1)"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)" fill="#064782"><path d="M0 10C0 4.484 4.484 0 10 0s10 4.484 10 10-4.484 10-10 10S0 15.516 0 10zm18.75 0c0-4.823-3.927-8.75-8.75-8.75S1.25 5.177 1.25 10s3.927 8.75 8.75 8.75 8.75-3.927 8.75-8.75z"/><path d="M8.125 10L12.5 5.625V3.75L6.25 10l6.25 6.25v-1.875L8.125 10z"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(90 10 10)" d="M0 0h20v20H0z"/></clipPath></defs></svg></button>
          <button class="btn" :disabled="!hasNext" @click="changePage(page + 1)"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)" fill="#064782"><path d="M20 10c0 5.516-4.484 10-10 10S0 15.516 0 10 4.484 0 10 0s10 4.484 10 10zM1.25 10c0 4.823 3.927 8.75 8.75 8.75s8.75-3.927 8.75-8.75S14.823 1.25 10 1.25 1.25 5.177 1.25 10z"/><path d="M11.875 10L7.5 14.375v1.875L13.75 10 7.5 3.75v1.875L11.875 10z"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(-90 10 10)" d="M0 0h20v20H0z"/></clipPath></defs></svg></button>
          <button class="btn" :disabled="page === totalPages" @click="changePage(totalPages)">
            <svg viewBox="0 0 20 20" width="20" height="20"  fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 10a10 10 0 01-20 0 10 10 0 0120 0zM1.25 10a8.76 8.76 0 0017.5 0 8.76 8.76 0 00-17.5 0z" fill="#064782"/><path d="M9.88 10L5.5 14.37v1.88L11.75 10 5.5 3.75v1.87L9.88 10z" fill="#064782"/><path d="M13.88 10L9.5 14.37v1.88L15.75 10 9.5 3.75v1.87L13.88 10z" fill="#064782"/></svg>
          </button>
        </div>
      </div>
      <div class="flex items-center pagination-field">
        <label class="basic-pagination-label mr-1">| Go to page : </label>
        <form @submit.prevent="goToPage(searchInput)">
          <input type="text" class="mr-1" style="max-width:60px" v-model.number="$v.searchInput.$model">
        </form>
        <button class="srp-btn" @click="goToPage(searchInput)"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 56.97 56.97" width="15" height="15"><path d="M55.15 51.89l-13.56-14.1A22.93 22.93 0 0046.99 23c0-12.68-10.32-23-23-23s-23 10.32-23 23 10.31 23 23 23c4.76 0 9.3-1.44 13.17-4.16l13.66 14.2a2.98 2.98 0 004.24.09 3 3 0 00.09-4.24zM23.98 6c9.38 0 17 7.63 17 17s-7.62 17-17 17-17-7.63-17-17 7.63-17 17-17z"/></svg></button>
      </div>
    </div>
    <div  v-if="paginationType == 'normal'">
      <button class="circular ui icon tiny button blue-btn-circle" :disabled="page === 1" @click="changePage(1)">
        <i class="angle double left icon"></i>
      </button>
      <button :disabled="!hasPrev"  class="circular ui icon tiny button blue-btn-circle"  @click="changePage(page - 1)">
        <i class="angle left icon"></i>
      </button>
      <button v-for="page_number in pages" :disabled="page === page_number" @click="changePage(page_number)" class="circular ui icon tiny button blue-btn-circle">
        {{page_number}}
      </button>
      ​
      <button :disabled="!hasNext" class="circular ui icon tiny button blue-btn-circle" @click="changePage(page + 1)">
        <i class="angle right icon"></i>
      </button>
      <button class="circular ui icon tiny button blue-btn-circle" :disabled="page === totalPages" @click="changePage(totalPages)">
        <i class="angle double right icon"></i>
      </button>
    </div>
    <div  v-if="paginationType == 'superBasic'">
      <div class="flex items-center justify-between">
        <a href="#" class="simple-pagination prev"  :class="{disabled:!hasPrev}" @click="changePage(page - 1,hasPrev)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
          Previous
        </a>
        <a href="#" class="simple-pagination next" :class="{disabled:!hasNext}" @click="changePage(page + 1,hasNext)">
          Next
          <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import {required,integer} from "vuelidate/lib/validators"
export default {
  name:'pagination',
  props: {
    paginationType:{
      type:String,
      default:"normal"
    },
    page: {
      type: Number,
      default: 1
    },
    count: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 5
    },
    pageRange: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      searchInput:''
    }
  },
  validations:{
    searchInput:{required,integer}
  },
  watch: {
    count(newValue, oldValue) {
      log('watching count');
      this.searchInput= ''
    }
  },
  methods:{
    changePage(page,valid = true) {
      if(!valid){
        return
      }
      this.$emit("setPage", page);
    },
    goToPage(page) {
      if(!this.$v.$invalid){
        if(this.searchInput  <= this.totalPages){
          this.changePage(page);
        }
      }
    }
  },
  computed:{
    pages() {
      let pages = [];
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }
      return pages;
    },
    rangeStart() {
      let start = this.page - this.pageRange;
      return start > 0 ? start : 1;
    },
    rangeEnd() {
      let end = (this.page + this.pageRange) + 1 ;
      return end < this.totalPages ? end : this.totalPages;
    },
    totalPages() {
      return Math.ceil(this.count / this.limit);
    },
    hasPrev() {
      return this.page > 1;
    },
    hasNext() {
      return this.page < this.totalPages;
    }
  }
}
</script>

<style scoped>
.simple-pagination{
  --tw-text-opacity: 1;
  color: rgba(107,114,128,var(--tw-text-opacity));
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.25rem;
  padding-left: .25rem;
  padding-top: 1rem;
  border-color: transparent;
  border-top-width: 2px;
  align-items: center;
  display: inline-flex;

}
.simple-pagination.disabled{
  opacity: 0.5;
  cursor:default

}
.simple-pagination.disabled:hover{
  opacity: 0.5;
  cursor:default;
  --tw-text-opacity: 1;
  color: rgba(107,114,128,var(--tw-text-opacity));

}
.simple-pagination:hover {
  --tw-text-opacity: 1;
  color: rgba(55,65,81,var(--tw-text-opacity));

}
.simple-pagination.next > svg{
  --tw-text-opacity: 1;
  color: rgba(156,163,175,var(--tw-text-opacity));
  width: 1.25rem;
  height: 1.25rem;
  margin-left: .5rem;
}
.simple-pagination.prev > svg{
  --tw-text-opacity: 1;
  color: rgba(156,163,175,var(--tw-text-opacity));
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .5rem;
}
.basic-pagination{
  display: flex;
  align-items: center;
  line-height: 1.2;
}
.basic-pagination > .basic-pagination-label{
  font-size: 1.1em;
  line-height: 1.2;
  font-weight: 500;
  margin-right: 0.8em;
  color: var(--main-color-blue);
}
.basic-pagination >.btns{
  display: flex;
}
.basic-pagination >.pages>.currentPage{
  background-color:#E6F7FF ;
  padding:0em 0.5em;
  border-radius: 5px;
}
.basic-pagination >.pages{
  color: var(--main-color-blue);
  font-weight: 500;
}
.basic-pagination >.btns>.btn{
  margin: 0em 0.3em !important;
  padding: 0em;
  line-height: 1;
  border:none;
  background-color: transparent;
  cursor: pointer ;
}
.srp-btn{
  background-color: var(--main-color-blue);
  border: none;
  padding: 5px 5px;
  border-radius: 3px;
  cursor:pointer;
  line-height: 0.23;
}
.pagination-field:focus{
  outline: none;
}
.pagination-field  > label{
  font-size: 1.1em;
  line-height: 1.2;
  font-weight: 500;
  margin-right: 0.8em;
  color: var(--main-color-blue);
}
.pagination-field > select,input,textarea{
  color: var(--main-color-blue) !important;
  font-weight: 500;
  border-radius: 0.5em !important;
  border: 1px solid var(--main-color-blue) !important;
  font-family: 'Poppins', sans-serif !important;
  padding: 5px 6px !important;
}
</style>
